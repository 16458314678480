// src/WalletConnection.js
import React, { useMemo, useCallback, useState } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { WalletModalProvider, WalletMultiButton, WalletDisconnectButton } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl, Connection, PublicKey, Transaction, SystemProgram } from '@solana/web3.js';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

const WalletConnection = () => {
    const [solAmount, setSolAmount] = useState(0);
    const [isWalletConnected, setIsWalletConnected] = useState(false);

    const network = WalletAdapterNetwork.Devnet;
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(
        () => [new PhantomWalletAdapter()],
        []
    );

    const handleTransaction = useCallback(async () => {
        if (!window.solana || !window.solana.isPhantom) {
            alert("Please install Phantom wallet");
            return;
        }

        const connection = new Connection(endpoint);
        const { solana } = window;
        const provider = solana;

        try {
            const publicKey = provider.publicKey;
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: new PublicKey("YOUR_DESTINATION_SOL_ADDRESS"),
                    lamports: solAmount * 1000000000, // Convert SOL to lamports
                })
            );

            const { signature } = await provider.signAndSendTransaction(transaction);
            await connection.confirmTransaction(signature);
            alert("Transaction successful!");
        } catch (error) {
            console.error("Transaction failed:", error);
            alert("Transaction failed!");
        }
    }, [solAmount, endpoint]);

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <WalletMultiButton onClick={() => setIsWalletConnected(true)} />
                    <WalletDisconnectButton onClick={() => setIsWalletConnected(false)} />
                    {isWalletConnected && (
                        <>
                            <input
                                type="number"
                                value={solAmount}
                                onChange={(e) => setSolAmount(Number(e.target.value))}
                                placeholder="Enter SOL amount"
                            />
                            <button onClick={handleTransaction} disabled={solAmount <= 0}>
                                Buy Now
                            </button>
                        </>
                    )}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default WalletConnection;
