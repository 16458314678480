// src/App.js
import React from 'react';
import WalletConnection from './WalletConnection';

const App = () => {
    return (
        <div className="App">
            <header className="App-header">
                <WalletConnection />
            </header>
        </div>
    );
};

export default App;
